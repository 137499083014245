import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
// import { Fragment } from 'react';
// import { Popover, Transition } from '@headlessui/react';
// import clsx from 'clsx';

// import { Button } from '@/components/marketing/Button';
import { Container } from '@/components/marketing/Container';
import { NavLink } from '@/components/marketing/NavLink';

// Files
const imgLogo = require('@/public/images/irysTeams.png');

/*
function MobileNavLink({ href, children }) {
  return (
    <Popover.Button as={Link} href={href} className="block w-full p-2">
      {children}
    </Popover.Button>
  );
}


function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round">
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0'
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0'
        )}
      />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation">
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5">
            <MobileNavLink href="#features">Features</MobileNavLink>
            <MobileNavLink href="#testimonials">Testimonials</MobileNavLink>
            <MobileNavLink href="#pricing">Pricing</MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink href="/login">Sign in</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}
*/

export function Header() {
  const { t } = useTranslation(['marketing']);

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            {/* <Logo className="h-10 w-auto" /> */}
            <Image
              src={imgLogo}
              alt="Picture of the author"
              width={50}
              height={50}
            />

            <span className="md:flex md:gap-x-6 ml-2 font-semibold">
              Irys Teams
            </span>
            {/* <div className="hidden md:flex md:gap-x-6">
              <NavLink href="#features">{t('features')}</NavLink>
              <NavLink href="#testimonials">Testimonials</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
            </div> */}
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {/* hidden */}
            <div className="md:block">
              <NavLink href="/login">{t('sign_in')}</NavLink>
            </div>
            <div className="md:block">
              <Link href="/login" legacyBehavior>
                <button
                  type="button"
                  className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-militar-600 hover:bg-militar-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-militar-500">
                  {t('sign_up_free')}
                </button>
              </Link>

              {/* <NavLink href="/login">{t('sign_in')}</NavLink> */}
            </div>
            {/* <Button href="/register" color="militar">
              <span>
                Get started <span className="hidden lg:inline">today</span>
              </span>
            </Button> */}
            {/* <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div> */}
          </div>
        </nav>
      </Container>
    </header>
  );
}
