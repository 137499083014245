import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

import { CallToAction } from '@/components/marketing/CallToAction';
import { Footer } from '@/components/marketing/Footer';
import { Header } from '@/components/marketing/Header';
import { Hero } from '@/components/marketing/Hero';
// import { Pricing } from '@/components/marketing/Pricing';
// import { PrimaryFeatures } from '@/components/marketing/PrimaryFeatures';
// import { SecondaryFeatures } from '@/components/marketing/SecondaryFeatures';
// import { Testimonials } from '@/components/marketing/Testimonials';

export default function Home() {
  const { t } = useTranslation(['marketing']);

  return (
    <>
      <Head>
        <title>{t('title')}</title>
        <meta name="description" content={t('title_slogan_1')} />
      </Head>
      <Header />
      <main>
        <Hero />
        {/* <PrimaryFeatures />
        <SecondaryFeatures /> */}
        <CallToAction />
        {/* <Testimonials />
        <Pricing /> */}
        {/* <Faqs /> */}
      </main>
      <Footer />
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale, ['marketing'])) // Locale props
    }
  };
}
